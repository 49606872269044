@import 'grid';
@import 'magnific-popup';
@import 'slicknav';


/******** PRIMARY SETTINGS *********/
html{
  font-family: 'proxima-nova', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  color: #767676;
  letter-spacing: 0.5px;

  @media (max-width:767px){
    font-size: 16px;
  }

  a{
    transition: all 0.3s ease;
    color: #5026E0;

    &:focus, &:active, &:visited{
      opacity: 1;
      outline: none;
      text-decoration: none;
    }

    &:hover{
      opacity: 0.7;
      text-decoration: none;
      color: #5026E0;
    }
  }

  p{
    line-height: 30px;

    @media (max-width:767px){
      line-height: 27px;
    }
  }

  @media (max-width:767px){
    line-height: 28px;
  }

  h1, h2, h3, h4, h5, h6{
    color: #0F0F0F;
    font-family: "miller-text", serif;
    font-weight: 600;
  }

  h1, .h1{
    font-size: 70px;
    line-height: 80px;
    letter-spacing: -3px;
  }

  h2, .h2{
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
  }

  h3, .h3{
    font-size: 36px;
    line-height: 46px;
    letter-spacing: -2px;
  }

  h4, .h4{
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -1.2px;
  }

  h5, .h5{
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -1px;
  }

  blockquote{
    line-height: 30px;
    letter-spacing: 0.5px;
    font-size: 17px;
    margin: 0;

    > footer{
      margin: 16px 0 0 20px;
      font-size: 15px;
      letter-spacing: 0.5px;
      font-style: normal;
      position: relative;
      color: #B3B3B3;

      cite{
        font-style: normal;

        &:before{
          content: '';
          display: inline-block;
          position: relative;
          left: 0;
          top: -4px;
          margin-right: 12px;
          background: #B3B3B3;
          width: 12px;
          height: 2px;
        }
      }
    }
  }

  *::selection {
    color: #fff !important;
    background: #5026E0 !important;
  }
}



/******** BUTTONS *********/
.btn{
  font-weight: 700;
  padding: 18px 50px;
  min-width: 200px;
  display: inline-block;

  background-size: 300% 100%;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;

  &:hover{
    opacity: 1;
    color: #fff;

    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }

  &-primary{
    box-shadow: 0px 8px 24px #D8CFFE;
    color: #fff;
    transition: all 0.2s ease-in-out;
    background-image: linear-gradient(31.95deg, #7535E2, #5026E0, #6510f7, #2d0e96);

    @media (max-width:767px){
      min-width: initial;
      padding: 16px 40px;
    }

    &:hover{
      box-shadow: 0px 20px 48px #b8aaf1;
    }

    &:focus, &:visited{
      color: #fff;
      outline: none;
      opacity: 1;
    }
  }
}



/******** OTHER SECTIONS *********/
.sectionTitle{
  display: flex;
  justify-content: space-between;

  .container{
    position: relative;

    &:before{
      position: absolute;
      left: -50px;
      content: '';
      height: 158px;
      width: 250px;
      background-image: url('../images/horizontal_bg.svg');

      @media (max-width: 767px){
        left: -130px;
        top: -16px;
        opacity: 0.6;
      }
    }
  }

  .left{
    background-repeat: no-repeat;
    height: 160px;
    display: flex;
    align-items: center;

    @media (max-width:767px){
        justify-content: center;
        height: auto;
    }

    h1{
      @media (max-width:767px){
        margin: 24px 0 0;
        font-size: 44px;
        line-height: 42px;
        text-align: center;
      }
    }
  }

  .right{
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 160px;

    @media (max-width:767px){
        justify-content: center;
        height: auto;
    }

    .h3{
      font-weight: 400;
      color: #B3B3B3;

      @media (max-width:767px){
        margin: 0;
        font-size: 32px;
      }
    }
  }
}

.sectionFooter{
  text-align: center;

  a{
    position: relative;

    &:before{
      position: absolute;
      z-index: -1;
      top: -64px;
      left: -48px;
      content: '';
      height: 277px;
      width: 176px;
      background-image: url('../images/vertical_bg.svg');

      @media (max-width: 767px){
        opacity: 0.6;
      }
    }
  }
}


.subpage{
  .slider{
    @media (max-width:1600px){
        padding: 185px 0 80px;
    }

    @media (max-width:767px){
        padding: 130px 0 80px;
    }

    h2{
      color: #767676;

      @media (max-width:1600px){
          margin: 24px 0 0;
      }

      @media (max-width: 1325px){
        font-size: 20px;
        margin: 12px 0 0;
      }

      @media (max-width:767px){
          display: block;
          font-size: 17px;
      }
    }
  }

  main{
    padding-top: 64px;

    @media (max-width:767px){
        padding-top: 24px;
    }
  }

  .portfolio{
    margin-top: -100px;
  }
}

.subpage-contact{
  .slider{
    @media (max-width: 767px){
      padding: 130px 0 36px;
    }

    @media (max-width: 374px){
      padding: 130px 0 36px;
    }
  }
}



/******** HEADER *********/
.header.nav-hide {
  top:-144px;
}

.header.open.nav-hide {
  top:0px;
}

.header{
  opacity:1;
  width:100%;
  // background: #fff;
  left:0;
  top:0;
  position:fixed;
  box-sizing:border-box;
  z-index:100;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  @media (max-width:767px){
      background: #fff !important;
  }

  &--logo{
    display: flex;
    align-items: center;
    height: 140px;

    @media (max-width:1920px){
        height: 130px;
    }

    @media (max-width:1600px){
        height: 120px;
    }

    @media (max-width:1366px){
        height: 90px;
    }

    @media (max-width: 1325px){
        height: 120px;
    }

    @media (max-width:767px){
        height: 80px;
    }

      a{
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        padding: 10px 0;

        &:hover{
          opacity: 0.7;
        }

        @media (max-width:767px){
          position: relative;
          top: 3px;
        }

        svg{
         height: 32px;

         @media (max-width:767px){
             height: 26px;
         }
       }
      }
    }

  &--nav{
    display: flex;
    align-items: center;
    height: 140px;
    justify-content: center;

    @media (max-width:1920px){
        height: 130px;
    }

    @media (max-width:1600px){
        height:120px;
    }

    @media (max-width:1366px){
        height:90px;
    }

    @media (max-width: 1325px){
        height: 120px;
    }

    @media (max-width:767px){
        height: 80px;
        justify-content: flex-end;
    }

    ul#desktop-nav{
      display: flex;
      list-style-type: none;
      text-decoration: none;
      padding: 0;
      margin: 0;

      @media (max-width:1325px){
        position: relative;
        justify-content: flex-end;
        top: 2px;
        left: 17px;
      }

      @media (max-width:991px){
        position: relative;
        left: 20px;
      }

      @media (max-width:767px){
        display: none;
      }

      &:hover{
        li{
          a{
            color: #B3B3B3;
          }
        }
      }

      li{
        position: relative;
        margin-left: 84px;
        display: flex;

        @media (max-width:1325px){
          margin-left: 56px;
        }

        @media (max-width:991px){
          margin-left: 36px;
          align-items: center;
        }

        &:first-child{
          margin-left: 0;
        }

        a{
          color: #0F0F0F;
          padding: 17px 10px;
          font-size: 15px;

          &:hover{
            color: #5026E0;
            opacity: 1;
          }
        }
      }
    }

    .language{
      position: relative;
      top: 5px;
      display: none;

      @media (max-width:767px){
          display: block;
      }

      svg{
        height: 22px;
      }
    }
  }

  &--links{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 140px;

    @media (max-width:1920px){
        height: 130px;
    }

    @media (max-width:1600px){
        height:120px;
    }

    @media (max-width:1366px){
        height:90px;
    }

    @media (max-width: 1325px){
        height: 120px;
        justify-content: flex-end;
    }

    @media (max-width:767px){
        display: none;
    }

    .dribbble{
      color: #5026E0;
      margin-right: 44px;
      font-size: 15px;
      padding: 15px 0;

      @media (max-width: 1325px){
          margin-right: 24px;
      }

      svg{
        position: relative;
        top: 6px;
        margin-right: 10px;

        @media (max-width: 1325px){
            margin-right: 8px;
        }
      }

      span{
        @media (max-width: 1325px){
            display: none;
        }
      }

      &-txt2{
        @media (max-width: 991px){
            display: none;
        }
      }
    }

    .language{
      color: #B3B3B3;
      font-size: 15px;
      padding: 15px 0;

      svg{
        position: relative;
        top: 7px;
        margin-right: 8px;

        @media (max-width: 1325px){
            margin-right: 6px;
        }
      }

      span{
        @media (max-width: 1325px){
            display: none;
        }
      }
    }
  }
}





/******** SLIDER *********/
.top-bg{
  background-size: 2560px;
  padding-bottom: 170px;
  background-position: center 0;
  background-repeat: no-repeat;

  @media (max-width:1920px){
      background-position: center -30px;
  }

  @media (max-width:1600px){
    background-position: center -95px;
    background-size: 2450px;
  }

  @media (max-width:1366px){
    background-position: center -172px;
    background-size: 2386px;
  }

  @media (max-width:1325px){
    background-position: center -135px;
    background-size: 2386px;
  }

  @media (max-width:991px){
    background-position: center 0;
    background-size: 2200px;
  }

  @media (max-width:767px){
    background-position: center 216px;
    background-size: 1013px;
    padding-bottom: 39px;
  }

  @media (max-width:374px){
    background-position: center 200px;
    background-size: 940px;
    padding-bottom: 26px;
  }
}

.slider{
  padding: 240px 0 120px;

  @media (max-width:1920px){
      padding: 220px 0 120px;
  }

  @media (max-width:1600px){
      padding: 164px 0 120px;
  }

  @media (max-width:1366px){
      padding: 148px 0 120px;
  }

  @media (max-width:1325px){
      padding: 156px 0 120px;
  }

  @media (max-width:991px){
      padding: 180px 0 120px;
  }

  @media (max-width:767px){
      padding: 110px 0 80px;
  }

  @media (max-width:374px){
      padding: 90px 0 70px;
  }

  h1{
    margin: 0;
    text-align: center;

    @media (max-width: 1325px){
        font-size: 60px;
    }

    @media (max-width:767px){
        font-size: 42px;
        line-height: 50px;
        padding: 0 36px;
    }

    @media (max-width:374px){
      padding: 0 16px;
    }

    .decorative{
      font-weight: 400;
      font-style: italic;
    }
  }

  h2{
    font-size: 22px;
    font-family: "proxima-nova", sans-serif;
    color: #0F0F0F;
    font-weight: 700;
    margin: 48px 0 0;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;

    @media (max-width:1366px){
        margin: 24px 0 0;
    }

    @media (max-width:767px){
        display: none;
    }
  }

  &-content{
    padding: 0 80px;

    @media (max-width: 767px){
      padding: 0 40px;
    }

    @media (max-width: 580px){
      padding: 0;
    }
  
    p{
      text-align: center;
      margin: 32px 0 0;
  
      @media (max-width:1366px){
          margin: 26px 0 0;
      }
  
      @media (max-width:767px){
        margin: 26px 0 0;
      }
  
      @media (max-width:374px){
        margin: 16px 0 0;
      }
    }
  
    .buttons{
      display: flex;
  
      .btn{
        margin: 56px auto 0;
  
        @media (max-width:1366px){
            margin: 36px auto 0;
        }
  
        @media (max-width:767px){
          margin: 36px auto 0;
        }
  
        @media (max-width:374px){
            margin: 16px auto 0;
        }
      }
    }
  }
}


/******** MAIN *********/
main{
  padding-top: 104px;

  @media (max-width:1600px){
      padding-top: 24px;
  }

  @media (max-width:1366px){
      padding-top: 0;
  }

  @media (max-width:767px){
      overflow: hidden;
      padding-top: 60px;
  }



  /******** SERVICES *********/
  .services{
    padding: 0 0 100px;
    margin-bottom: 120px;

    @media (max-width:1600px){
        margin-bottom: 60px;
    }

    @media (max-width:1366px){
        margin-bottom: 30px;
    }

    &--items{
      margin: 80px 0;

      &--one{
        margin-bottom: 120px;

        @media (max-width: 767px){
          margin-bottom: 60px;
        }

        &:nth-child(3){
          clear: both;
        }

        img{
          height: 96px;
          margin-bottom: 36px;
        }

        .h4{
          margin: 0 0 16px;
        }

        p{
          margin: 0;
        }

        .ios{
          color: #000;

          svg{
            position: relative;
            top: 2px;
          }
        }

        .android{
          color: #AAC148;

          svg{
            position: relative;
            top: 4px;
          }
        }

        .google-ads{
          color: #3C8BD9;

          svg{
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }





  /******** SERVICE SUBPAGE *********/
  .service-subpage{
    margin-bottom: 140px;

    @media (max-width:1600px){
        margin-bottom: 60px;
    }

    @media (max-width:1366px){
        margin-bottom: 30px;
    }

    &--icons{
      margin: 60px 0 24px;

      ul{
        list-style-type: none;
        padding: 0;
        margin: 0;

        li{
          margin-bottom: 44px;

          img{
            height: 88px;

            @media (max-width: 374px){
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    &--projects{
      &--title{
        height: 64px;
        display: flex;
        align-items: center;
        margin: 48px 0 36px;

        @media (max-width: 767px){
          display: block;
          text-align: center;
          margin: 12px 0 48px;
        }

        .left{
          h3{
            color: #E4E4E4;
            font-weight: 400;

            @media (max-width: 767px){
              margin: 12px 0;
            }
          }
        }

        .right{
          display: flex;
          justify-content: flex-end;

          @media (max-width: 767px){
            justify-content: center;
          }

          a{
            img{
              margin-left: 10px;
            }
          }
        }
      }

      .portfolio--items{
        margin: 0 0 30px;
      }
    }

    &--text{
      margin-top: -30px;
      clear: both;
    }
  }



  /******** PORTFOLIO *********/
  .portfolio{
    margin-bottom: 140px;

    @media (max-width:1600px){
        margin-bottom: 80px;
    }

    @media (max-width:1366px){
        margin-bottom: 60px;
    }

    .container{
      @media (max-width: 767px){
        padding:0;
      }
    }

    &--items{
      margin: 80px 0 0;

      @media (max-width:991px){
        margin: 40px 0 0;
      }

      .sectionFooter{
        margin-top: 64px;
        padding-bottom: 156px;
      }

      &--one{
        margin-bottom: 48px;

        @media (max-width: 767px){
          padding:0;
          margin-bottom: 16px;
        }

        &:nth-child(7), &:nth-child(11){
          clear: both;
        }

        &:hover{
          cursor: pointer;

          .view-category{
            margin-top: -20px;
          }

          .portfolio--items--one--img{
            transform: scale(.97);

            img{
              transform: scale(1.1);
            }
          }
        }
        &--img{
          overflow: hidden;
          background-image: url('../images/loader.svg');
          background-repeat: no-repeat;
          background-position: 50% 50%;
          transform: scale(1);
          transition: transform .3s ease-in-out;

          img{
            width: 100%;
            transition: transform .3s ease-in-out;
            transform: scale(1);
            display: block;

            &:not([src]){
              visibility: hidden;
            }
          }
        }
        &--text{
          text-align: center;

          > .h4{
            margin: 24px 0 0;
            padding: 0 10px;
          }

          .view{
            height: 27px;
            width: auto;
            margin-bottom: 5px;
            overflow: hidden;
            box-sizing: border-box;

            &-category{
              font-size: 15px;
              line-height: 25px;
              transition: all 0.25s ease-in-out;
            }

            &-hover{
              font-size: 15px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }





  /******** OPINIONS *********/
  .opinions{
    &--items{
      margin-top: 80px;

      &--one{
        margin-bottom: 56px;

        .profile{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border: 2px solid #E4E4E4;
          border-radius: 100%;

          > img{
            height: 56px;
            width: 56px;
          }

          &--icon{
            position: absolute;
            right: -16px;
            top: -8px;
            width: 40px;
            height: 40px;
            background: linear-gradient(65.82deg, #7535E2 15.38%, #5026E0 54.53%);
            box-shadow: 0px 4px 16px #D8CFFE;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        > h2{
          margin: 24px 0 16px;
        }
      }
    }
  }





  /******** BANNER *********/
  .banner{
    margin-top: 100px;

    @media (max-width:1325px){
      margin-top: 60px;
    }

    @media (max-width: 767px){
      padding-bottom: 40px;
    }

    &--item{
      display: flex;

      .row{
        position: relative;
      }

      &--bg{
        background: #F9F9F9;
        display: block;
        width: 100%;
        height: 480px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;

        @media (max-width: 991px){
          height: 579px;
        }

        @media (max-width: 767px){
          height: 580px;
        }

        @media (max-width: 374px){
          height: 610px;
        }
      }

      &--left{
        @media (max-width: 991px){
          text-align: center;
        }

        @media (max-width:767px){
            padding: 0;
        }

        img{
          width: 491px;
          margin: 30px 60px;

          @media (max-width:1325px){
            width: 391px;
            margin: 30px 15px;
          }

          @media (max-width: 767px){
            width: 290px;
            margin: 20px 0;
          }
        }
      }
      &--right{
        @media (max-width: 991px){
          text-align: center;
        }

        .big{
          color: #5026E0;
          font-size: 22px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0.7px;
          margin: 56px 0 16px;

          @media (max-width: 991px){
            margin: 0;
          }

          @media (max-width: 767px){
            font-size: 18px;
          }
        }

        > h1{
          margin: 0 0 24px;

          @media (max-width:1325px){
            font-size: 36px;
            line-height: 46px;
          }

        }

        > p {
          margin: 0 0 36px;

          @media (max-width: 991px){
            margin: 0 0 24px;
          }
        }

        > .btn{
          margin: 0;
        }
      }
    }
  }



  .contact{
    margin-top: -74px;

    @media (max-width: 767px){
      margin-top: 0;
    }

    &--items{
      &--one{

        h3{
          color: #B3B3B3;
          font-weight: 400;
          line-height: 30px;
          text-align: center;

          @media (max-width: 767px){
            padding-bottom: 20px;
            font-size: 32px;
          }

          a{
            font-weight: 700;
            color: #0F0F0F;

            @media (max-width: 767px){
              display: block;
              margin-top: 16px;
              font-size: 24px;
            }

            &:hover{
              color: #5026E0;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .socials{
    margin: 120px 0 140px;

    @media (max-width: 767px){
      margin: 40px 0;
    }

    &--items{

      ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        @media (max-width: 767px){
          display: block;
        }

        li{
          display: flex;
          align-items: center;
          margin-left: 84px;

          @media (max-width: 767px){
            display: block;
            margin-left: 0;
            margin-bottom: 24px;
          }

          &:first-child{
            margin-left: 0;
          }

          a{
            display: flex;
            align-items: center;
            font-size: 22px;
            color: #767676;
            font-weight: 700;

            @media (max-width: 767px){
              display: block;
              text-align: center;
            }

            svg{
              margin-right: 16px;

              @media (max-width: 767px){
                position: relative;
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}





/******** FOOTER *********/
.footer{
  margin: 100px 0 60px;

  @media (max-width:1325px){
    margin: 80px 0 60px;
  }

  @media (max-width: 767px){
    margin: 40px 0 60px;
  }

  &--logo{
    text-align: center;
    line-height: 1;
  }

  &--nav{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 44px;

    @media (max-width:767px){
        height: auto;
    }

    ul{
      display: flex;
      list-style-type: none;
      text-decoration: none;
      padding: 0;
      margin: 0;
      position: relative;
      left: -6px;

      @media (max-width:767px){
          display: block;
          left: 0;
      }

      &:hover{
        li{
          a{
            color: #B3B3B3;
          }
        }
      }

      li{
        position: relative;
        margin-left: 102px;
        display: flex;

        @media (max-width:767px){
            margin-left: 0;
            display: block;
            text-align: center;
        }

        &:first-child{
          margin-left: 0;
        }

        a{
          font-size: 15px;
          letter-spacing: 0.5px;
          color: #0F0F0F;
          padding: 10px;

          @media (max-width:767px){
              display: block;
          }

          &:hover{
            color: #5026E0;
            opacity: 1;
          }
        }
      }
    }
  }

  &--copyright{
    margin-top: 44px;

    p{
      font-size: 15px;
      letter-spacing: 0.5px;
      color: #B3B3B3;
      text-align: center;
    }
  }
}














/**************************************** EXTRAS *****************************************/
/******** SCROLL TOP ARROW *********/
.circle-action {
	fill: transparent;
	stroke: #000;
}

.is-inner{
	stroke-width: 2;
	opacity:0.2;
}

.is-outer{
	stroke-width: 2;
	stroke-dasharray: 227;
	stroke-dashoffset: 227;
	transform-origin: 50%;
	transform: rotate(-90deg);
	transition: stroke-dashoffset 400ms linear;
}

.progress-page {
	position: fixed;
	right: 40px;
	bottom: 100px;
	height: 46px;
	width: 46px;
	border-radius:50px;
	box-shadow: inset  0 0 0 2px #E4E4E4;
	opacity:0;
	z-index:10;
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;

  @media (max-width:991px){
      bottom: 15px;
      right: 15px;
  }
}

.light-content .progress-page {
	box-shadow: inset  0 0 0 2px rgba(255, 255, 255, 0.2);
}

.progress-page.disable {
	opacity:0!important;
}

.progress-page.is-active.is-visible {
	opacity:1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);

}

.progress-page svg path {
	fill: none;
}

.progress-page .progress-circle{
  position: absolute;
  top: 0;
  left: 0;
}

.progress-page svg.progress-circle path {
	stroke: #5026E0;
	stroke-width: 3;
	box-sizing:border-box;
}

.light-content .progress-page svg.progress-circle path {
	stroke: rgba(255, 255, 255, 1);
}

.scrolltotop {
  z-index: 99;
	height: 46px;
	right: 0px;
	bottom: 100px;
	width: 46px;
	cursor:pointer;
	opacity:0;
	position:absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.scrolltotop.is-active.is-visible {
	opacity:1;
	bottom:0;
}

.scrolltotop.is-active.is-visible:hover {
	bottom:1px;
	opacity:0.7;
}

.arrow-top {
  border-bottom: 2px solid #5026E0;
  border-left: 2px solid #5026E0;
  height: 8px;
  transform: rotate(135deg);
  width: 8px;
  position: absolute;
  left: 19px;
  top: 16px;
}

.arrow-top-line {
	background-color: #5026E0;
	height: 14px;
	left: 50%;
	position: absolute;
	top: 16px;
	width: 2px;
	margin-left:-1px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.scrolltotop:hover .arrow-top-line {
	height:17px;
	top: 17px;
}







/******** GALLERY PORTFOLIO - MFP ZOOM *********/
.mfp-zoom-out-cur{
  height: 100%;
  overflow: hidden;
}

img.mfp-img{
  width: 100%;
  max-height: initial !important;
  max-width: 1600px !important;
}

.mfp-movie + .mfp-gallery .mfp-content{
  width: 80%;
  height: 90%;
  max-width: initial;

  @media (max-width:1024px){
    width: 100%;
    height: 80%;
  }
}






/******** MOBILE MENU *********/
#page-overlay{
  display: none;
  background: rgba(15,16,15,0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
}

#mobile-nav-content{
  display: none;
  position: fixed;
  width: 100%;
  top: 80px;
  background: white;
  z-index: 100;
  padding: 60px 0;

  ul{
    list-style-type: none;
    padding: 0 30px;
    margin: 0;

    li{
      a{
        text-decoration: none;
        font-size: 22px;
        margin-bottom: 22px;
        color: #0F0F0F;
        display: block;
      }
    }
  }
}

#mobile-nav-icon {
  display: none;
  width: 42px;
  height: 42px;
  position: relative;
  margin: 4px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  @media (max-width:767px){
      display: block;
  }

  span{
    display: block;
    position: absolute;
    height: 3px;
    width: 20px;
    background: #0F0F0F;
    border-radius: 0;
    opacity: 1;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 19px;
    }

    &:nth-child(2) {
      top: 26px;
    }
  }
}

#mobile-nav-icon.open{
  span{
    &:nth-child(1) {
      top: 23px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    // &:nth-child(2) {
    //   opacity: 0;
    //   right: -10px;
    // }

    &:nth-child(2) {
      top: 23px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}
